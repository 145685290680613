export const fungiDictionary = [
  { name: "Chestnut", latin: "Agaricus bisporus" },
  { name: "Shitake", latin: "Lentinula edodes" },
  { name: "Lion's Mane", latin: "Hericium erinaceus" },
  { name: "Chanterelle", latin: "Cantharellus cibarius" },
  { name: "Common Morel", latin: "Morchella esculenta" },
  { name: "Enoki", latin: "Flammulina velutipes" },
  { name: "Porcini", latin: "Boletus edulis" },
  { name: "Maitake", latin: "Grifola frondosa" },
  { name: "Reishi", latin: "Ganoderma lingzhi" },
  { name: "Buna Shimeji", latin: "Hypsizygus tessellatus" },
  { name: "Beech", latin: "Hypsizygus tesselatus" },
  { name: "Blewit", latin: "Clitocybe nuda" },
  { name: "Chaga", latin: "Inonotus obliquus" },
  { name: "Chicken of the Woods", latin: "Laetiporus sulphureus" },
  { name: "Giant Puffball", latin: "Calvatia gigantea" },
  { name: "Hen of the Woods", latin: "Grifola frondosa" },
  { name: "Nameko", latin: "Pholiota microspora" },
  { name: "Pioppino", latin: "Agrocybe aegerita" },
  { name: "Shaggy Mane", latin: "Coprinus comatus" },
  { name: "Wine Cap", latin: "Stropharia rugosoannulata" },
  { name: "Turkey Tail", latin: "Trametes versicolor" },
  { name: "Pearl Oyster", latin: "Pleurotus Ostreatus" },
  { name: "Blue Oyster", latin: "Pleurotus Columbinus" },
  { name: "Golden Oyster", latin: "Pleurotus Citrinopileatus" },
  { name: "King Oyster", latin: "Pleurotus eryngii" },
  { name: "Pink Oyster", latin: "Pleurotus Djamor" },
  { name: "Black Trumpet", latin: "Craterellus cornucopioides" },
  { name: "Caesar’s", latin: "Amanita caesarea" },
  { name: "Cauliflower", latin: "Sparassis crispa" },
  { name: "Charcoal ", latin: "Russula cyanoxantha" },
  { name: "Crab Brittlegill", latin: "Russula xerampelina" },
  { name: "Dryad’s Saddle", latin: "Cerioporus squamosus" },
  { name: "Green-cracking Russula", latin: "Russula virescens " },
  { name: "Dark Honey Fungus", latin: "Armillaria ostoyae" },
  { name: "King Bolete", latin: "Boletus edulis" },
  { name: "Parasol", latin: "Macrolepiota procera" },
  { name: "Red Pine", latin: "Lactarius deliciosus" },
  { name: "Slippery ", latin: "Suillus luteus" },
  { name: "Straw", latin: "Volvariella volvacea" },
]

export const substrates = [
  { name: "Brown Rice Flour" },
  { name: "Coco Coir & Vermiculite" },
  { name: "Coffee Grounds" },
  { name: "Grain" },
  { name: "Hardwood Sawdust" },
  { name: "Manure" },
  { name: "Soy Hulls" },
  { name: "Straw" },
  { name: "Logs" },
  { name: "Garden Bed" },
  { name: "Mulch" },
  { name: "Cardboard" },
]

/**
 * Sort list of objects by the 'name' property
 * @param {*} list 
 * @returns 
 */
export const sortByName = (list) => {
  return list.sort((a, b) => a.name > b.name ? 1 : -1)
}