import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead';
import ReactGA from 'react-ga4';
import { sortByName, fungiDictionary, substrates } from '../data'
import PrintableLabel from './PrintableLabel'

import './Label.scss'

function Label() {

  const [label, setLabel] = useState({
    fungi: null,
    inoculationDate: new Date().toISOString().split('T')[0],
    showQRCode: false,
    substrate: "",
    barcode: "",
  });

  const _fungiTypeaheadOpts = (results, menuProps) => {
    return (
      <Menu {...menuProps}>
        {results.map((result, idx) => (
          <MenuItem
            key={result.name}
            option={result}
            position={idx}
            onClick={() => setLabel({ ...label, fungi: result })}>
            {result.name} <em className="px-2 text-muted">{result.latin}</em>
          </MenuItem>
        ))}
      </Menu>
    );
  };

  const _updateSubstrate = (name) => {
    if (typeof name === 'object') {
      setLabel({ ...label, substrate: name.name })
    } else {
      setLabel({ ...label, substrate: name })
    }

  }

  const _substrateTypeaheadOpts = (results, menuProps) => {
    return (
      <Menu {...menuProps}>
        {results.map((result, idx) => (
          <MenuItem
            key={result.name}
            option={result}
            position={idx}
            onClick={() => _updateSubstrate(result.name)}>
            {result.name}
          </MenuItem>
        ))}
      </Menu>
    );
  };


  const onSubmit = (e) => {
    e.preventDefault()
    
    ReactGA.event({
      category: "Print Label",
      action: "User printed 4x6 label",
    });

    window.print()
    
    return false;
  };

  return (
    <>
      <Form className="label label-form mt-2"
        onSubmit={onSubmit}>

        <Form.Group className="mb-3 fungi-typeahead" controlId="fungi">
          <Form.Label>What are you growing?</Form.Label>
          <Typeahead
            id="selectedFungi"
            allowNew
            filterBy={['name', 'latin']}
            labelKey="name"
            placeholder="Type your Fungi here"
            onChange={(result) => setLabel({ ...label, fungi: result[0] })}
            onInputChange={(result) => setLabel({ ...label, fungi: { name: result, latin: "" } })}
            options={sortByName(fungiDictionary)}
            renderMenu={_fungiTypeaheadOpts}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="inoculationDate">
          <Form.Label>Inoculation Date</Form.Label>
          <Form.Control type="date" name='inoculationDate'
            onChange={(e) => setLabel({ ...label, inoculationDate: e.target.value })}
            value={label.inoculationDate}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="substrate">
          <Form.Label>Substrate (optional)</Form.Label>

          <Typeahead
            id="substrate"
            allownew
            filterBy={['name']}
            labelKey="name"
            placeholder="Type your substrate here"
            onChange={(result) => _updateSubstrate(result[0])}
            onInputChange={(result) => _updateSubstrate(result)}
            options={sortByName(substrates)}
            renderMenu={_substrateTypeaheadOpts}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="barcode">
          <Form.Label>Barcode # (optional)</Form.Label>
          <Form.Control type="text" label="barcode"
            placeholder="Barcode number"
            onChange={(e) => setLabel({ ...label, barcode: e.target.value })}
            value={label.barcode}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="showQRCode">
          <Form.Check type="checkbox"
            label="Display QR Code"
            onChange={(e) => setLabel({ ...label, showQRCode: e.target.checked })}
            checked={label.showQRCode}
          />
        </Form.Group>

        <Button variant="primary" type="submit" disabled={label.fungi === null}>
        <i className="fa fa-print"></i> Print 4x6 Label
        </Button>

      </Form>

      {label.fungi !== null ?
        <PrintableLabel
          className="do-print"
          fungi={label.fungi}
          substrate={label.substrate}
          inoculation={label.inoculationDate}
          barcode={label.barcode}
          showQRCode={label.showQRCode}
        /> : ""}
    </>
  )
}

export default Label;
