import React from 'react';
import QRCode from 'qrcode.react';
import Barcode from 'react-barcode';

import './Label.scss'

function PrintableLabel({
  fungi = null,
  substrate = "",
  inoculation = "",
  barcode = "",
  showQRCode = false }) {

  var fungiText = ""
  var fungiLatin = ""
  var wikipediaURL = ""

  if (fungi !== null && fungi !== undefined) {
    if (fungi.name !== undefined) {
      fungiText = fungi.name
    }    
    if (fungi.latin !== undefined) {
      fungiLatin = fungi.latin
      wikipediaURL = fungi.latin.toLowerCase().charAt(0).toUpperCase() + fungi.latin.slice(1); 
      wikipediaURL = wikipediaURL.replace(" ", "_")
    }
  }  

  // fungiQRCode = `https://mycologyfriend.com/l/${encodeURIComponent(barcode)}`
  var fungiQRCode = ""

  if (barcode.length > 0) {
    fungiQRCode = `${barcode}`
  } else {

    fungiQRCode += inoculation + " "

    if (fungiText.length > 0) {
      fungiQRCode += fungiText
    }

    if (fungiLatin.length > 0) {
      fungiQRCode += ` (${fungiLatin})`
    }

    if (substrate.length > 0) {
      fungiQRCode += ` on ${substrate} substrate`
    }
  }

  return (
    <>
      <div className="label preview text-muted mt-2 no-print">
        Print Preview
      </div>

      <div className="label label-4x6">

        <div className="label label-header text-center">
          <div className="label label-header-fungi">
            {fungiText}
          </div>

          {fungiLatin.length > 0 ?
            <div className="label label-header-latin">
              <em>                
                <a target="_blank" rel="noreferrer" href={`https://en.wikipedia.org/wiki/${wikipediaURL}`}>{fungi.latin}</a>
              </em>
            </div> : ""}

          <div className="label label-header-inoculation">
            <strong>Inoculation Date:</strong>
            &nbsp; {inoculation}
          </div>

          {substrate.length > 0 ? <div className="label label-header-substrate">
            <strong>Substrate:</strong>
            &nbsp; {substrate}
          </div> : ""}
        </div>

        <div className="label label-notes">
          <h4>Grow Notes</h4>
        </div>

        {/* QR Code w/text*/}

        {showQRCode === true ?
          <div className="label label-qrcode">
            <div className="label label-qrcode-code">
              <QRCode value={fungiQRCode} renderAs="svg" />
            </div>
            <div className="label label-qrcode-text">
              <strong>QR Code Data</strong>
              <br />{fungiQRCode}
            </div>
          </div> : ""}

        {/* Standlone QR Code */}

        {/* {showQRCode === true ?
          <div className="label label-qrcode text-center">
            <div className="label label-qrcode-code-full">
              <QRCode value={fungiQRCode} renderAs="svg" />
            </div>
          </div> : ""} */}

        {barcode.length > 0 ?
          <div className="label label-barcode">
            <Barcode value={barcode} />
          </div> : ""}
      </div>

    </>
  )
}

export default PrintableLabel;


