import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from "history";
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import ReactGA from 'react-ga4';
import rootReducer from './reducers'
import Navigation from './components/Navigation'
import Home from './components/Home'
// import Footer from './components/Footer';
import Label from './components/Label';
import About from './components/About';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'font-awesome/css/font-awesome.min.css';
import './App.css';

ReactGA.initialize("G-TMZTVNK273");

function App() {

  const appHistory = createBrowserHistory();
  const store = createStore(rootReducer)

  // Initialize google analytics page view tracking
  appHistory.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  });

  return (

    <Provider store={store}>
      <Router history={appHistory}>

        <div className="app">

          <header className="app">
            <Navigation />
          </header>

          <section className="container">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/label" component={Label} />
              <Route exact path="/about" component={About} />
            </Switch>
          </section>

          {/* <Footer /> */}

        </div>
      </Router>

    </Provider>
  );
}

export default App;
