import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './Navigation.css';

function Navigation() {

  // const location = useLocation();

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>

        <Navbar.Brand href="/">Mycology Friend</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav" >
          <Nav defaultActiveKey="/" as="ul">
            {/* <Nav.Item as="li">
              <NavLink to="/" exact={true} className="nav-item nav-link">Home</NavLink>
            </Nav.Item> */}
            <Nav.Item as="li">
              <NavLink to="/label" className="nav-item nav-link">Make a Label</NavLink>
            </Nav.Item>
          </Nav>

        </Navbar.Collapse>

        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav as="ul">
            <Nav.Item as="li" className="justify-content-end">
              <NavLink to="/about" className="nav-item nav-link">About</NavLink>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>

      </Container>
    </Navbar>
  )
}

export default Navigation;
