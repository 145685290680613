import React from 'react';

function About() {

  const YoutubeVideo = ({ id }) => {
    return (<object
      style={{ width: '350px', float: 'none', clear: 'both', margin: '2px auto' }}
      data={`https://www.youtube.com/embed/${id}`}>
    </object>    
    )
  }

  return (
    <div className="jumbotron">
      <div className="p-2">
        <h4 className="mb-3">What is this?</h4>
        <p>
          Print a label to track your mushroom grow on a bag, tub, or farm.
        </p>

        <p>
          Recommended equipment for printing 4x6 labels (most popular on Amazon):
        </p>
        <ul>
          <li>
            <a target="_blank" rel="noreferrer" href="https://www.amazon.com/gp/product/B01MA3EYC5/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B01MA3EYC5&linkCode=as2&tag=substantia-20&linkId=9e764132159e13c994c37250a2743dc5">ROLLO 4x6 Label Printer</a>
          </li>
          <li>
            <a target="_blank" rel="noreferrer" href="https://www.amazon.com/gp/product/B01M9AR01Q/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B01M9AR01Q&linkCode=as2&tag=substantia-20&linkId=aa5ef7b74aba28679de2131a2956faaa">ROLLO Thermal Direct Shipping Label</a> (500 4x6 Labels)
          </li>
        </ul>


        <p>
          Inspired by the kind teachers of mycology out there who educate the world about fungi, mushrooms, mycelial networks, and how to grow them.
        </p>

        <ul className="list-inline">
          <li className="list-inline-item"><YoutubeVideo id="1Q0un2GPsSQ" /></li>
          <li className="list-inline-item"><YoutubeVideo id="C2LIr06U7i0" /></li>
          <li className="list-inline-item"><YoutubeVideo id="SuE7DkWZwVw" /></li>
          <li className="list-inline-item"><YoutubeVideo id="zb8TAU6Y-4s" /></li>
          <li className="list-inline-item"><YoutubeVideo id="qjfpEW5IHp4" /></li>
          <li className="list-inline-item"><YoutubeVideo id="45b2t7fqhjA" /></li>
        </ul>

      </div>
    </div>
  )
}

export default About;
