import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import MailchimpSubscribe from "react-mailchimp-subscribe";

import './Home.css'

function Home() {

  const CustomForm = ({ status, message, onValidated }) => {

    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const handleSubmit = (e) => {
      e.preventDefault();
      email &&
        firstName &&
        lastName &&
        email.indexOf("@") > -1 &&
        onValidated({
          EMAIL: email,
          MERGE1: firstName,
          MERGE2: lastName,
        });
    }

    const Status = (<div className="status">

      {status === "sending" && (
        <div className="mc__alert mc__alert--sending">
          sending...
        </div>
      )}
      {status === "error" && (
        <div
          className="mc__alert mc__alert--error"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          className="mc__alert mc__alert--success"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
    </div>)

    return (

      <Container fluid className="signup signup-wrapper">
        <Form className="justify-content-md-center" onSubmit={(e) => handleSubmit(e)}>

          <h4 className="text-center">Hear about future updates!</h4>

          <Container fluid className="signup signup-form">
            <Row>
              <Col>
                <Form.Control
                  label="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                  type="text"
                  value={firstName}
                  placeholder="First"
                  required
                />

                <Form.Control
                  label="Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                  type="text"
                  value={lastName}
                  placeholder="Last"
                  required
                />


                <Form.Control
                  label="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  value={email}
                  placeholder="your@email.com"
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col className="p-2 text-center">
                <Button variant="primary" type="submit" disabled={email.length < 3}>
                  Subscribe
                </Button>

                {Status}
              </Col>
            </Row>


          </Container>




        </Form >
      </Container>
    );
  };


  return (
    <div className="jumbotron">
      <div className="p-5 text-center">
        <div className="mask">
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="text-dark">
              <h1 className="mb-3">Mycology Friend</h1>
              <h4 className="mb-3">Grow and track your fungi with labels</h4>
              <Link className="btn btn-outline-dark btn-lg" to="/label">
                Print Your Label Now
              </Link>
            </div>
          </div>
        </div>
      </div>


      <MailchimpSubscribe
        url="https://gmail.us5.list-manage.com/subscribe/post?u=87e21cdf54c23d587141d4d83&amp;id=e951d1cafc"
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={formData => subscribe(formData)}
          />
        )}
      />

      {/* <div>

<link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css">

<div id="mc_embed_signup">
<form action="https://gmail.us5.list-manage.com/subscribe/post?u=87e21cdf54c23d587141d4d83&amp;id=e951d1cafc" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
    <div id="mc_embed_signup_scroll">
	<h2>Subscribe</h2>
<div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
<div class="mc-field-group">
	<label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
</label>
	<input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
</div>
<div class="mc-field-group">
	<label for="mce-FNAME">First Name </label>
	<input type="text" value="" name="FNAME" class="" id="mce-FNAME">
</div>
<div class="mc-field-group">
	<label for="mce-LNAME">Last Name </label>
	<input type="text" value="" name="LNAME" class="" id="mce-LNAME">
</div>
	<div id="mce-responses" class="clear">
		<div class="response" id="mce-error-response" style="display:none"></div>
		<div class="response" id="mce-success-response" style="display:none"></div>
	</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_87e21cdf54c23d587141d4d83_e951d1cafc" tabindex="-1" value=""></div>
    <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
    </div>
</form>
</div>
<script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
</div>
</div> */}



    </div>
  )
}

export default Home;


